// Hooks
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Utils
import { apiCallError } from "../../../utils/common-utils";
import { getOnlyFullDate } from "../../../utils/date-functions";
// API
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

// Redux actions
import { getAllActivities } from "../../../redux/adminSlice/activitySlice";
import { getAllBookings } from "../../../redux/adminSlice/bookingSlice";

// Components
import DatePicker from "react-date-picker";
import Loader from "../../../Component/Loader";
import Table from "../../../Component/Table";
import Select from "react-select";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";
import "./styles.css";
import { getAllMerchants } from "../../../redux/adminSlice/merchantActionsSlice";
import { toast } from "react-toastify";
import SingleModel from "./singleBookingModel/SingleModel";


function ViewBookings() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [activity, setactivity] = useState({
    marchantId:"",
    merchantName:"",
    activityId: "",
    activityTitle: "",
    startDate: "",
    endDate: "",
  });
  const [searchText, setsearchText] = useState("");
  const [error, setError] = useState("");
  const [singleid, setsingleid] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [openModel, setopenModel] = useState(false);



  const { activities } = useSelector((state) => state.adminActivityActionSlice);
  const { merchants } = useSelector((state) => state.adminMerchantActionSlice);
  const { bookings } = useSelector((state) => state.bookingSlice);

  const [bookingData, setBookingData] = useState([]);
  useEffect(() => {
    allbookingdata()
    dispatch(getAllActivities());
    dispatch(getAllBookings());
    dispatch(getAllMerchants());
    setBookingData(bookings);
  }, []);

  useEffect(() => {
    if (bookings?.length > 0) {
      setBookingData(bookings);
    }
  }, [bookings]);

  const handleStartDateChange = (date) => {
    setactivity({
      ...activity,
      startDate: getOnlyFullDate(date),
    });
  };
  const handleEndDateChange = (date) => {
    setactivity({
      ...activity,
      endDate: getOnlyFullDate(date),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setisLoading(true);
      let res = await JSON_API["getAllBookings"](activity);
      if (res.isSuccess) {
        setisLoading(false);
        setBookingData(res?.data?.data);
      } else {
        setisLoading(false);
        apiCallError(res?.error?.message, setError);
      }
    } catch (error) {
      setisLoading(false);
      toast.error("Failed to get bookings due to ", error.message);
    }
  };  

const allbookingdata = async()=>{ 
  try {
    setisLoading(true);
    let res = await JSON_API["getAllBookings"](activity);
    console.log("454545", res)
    if (res.isSuccess) {
      setisLoading(false);
      setBookingData(res?.data?.data);
    } else {
      setisLoading(false);
      apiCallError(res?.error?.message, setError);
    }
  } catch (error) {
    setisLoading(false);
    toast.error("Failed to get bookings due to ", error.message);
  }

}




  const handleReset = (e) => {
    e.preventDefault();
    // console.log("clicked", inputRef.current);
    setactivity({
      marchantId:"",
    merchantName:"",
      activityId: "",
      activityTitle: "",
      startDate: "",
      endDate: "",
    });
  };

  const filterData = () => {
    if (searchText !== "") {
      return bookingData.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return bookingData;
  };

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          label: "SL",
          field: "sl",
          sort: "asc",
          width: 100,
        },
        {
          label: "Activity Name",
          field: "activity_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Booked By",
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Booking ID",
          field: "booking_id",
          width: 50,
        }, 
        {
          label: " Booking status",
          field: "Booking_status",
          width:50,
        },
        {
          label: "Review Status",
          field: "review_status",
          width:50,
        },
        {
          label: " Detail View",
          field: "detail_view",
          width:50,
        },
      ],
      rows:
        filterData()?.length > 0
          ? filterData().map((item, idx) => {
          
         
              return {
                sl: idx + 1,
                activity_name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {/* {item?.marchentFirstName?.charAt(0)?.toUpperCase() +
                            item?.marchentFirstName?.slice(1)?.toLowerCase() +
                            " " +
                            item?.marchentLastName?.charAt(0)?.toUpperCase() +
                            item?.marchentLastName?.slice(1)?.toLowerCase()} */}
                          {item?.activity[0]?.activityTitle}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),
                name: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.userDetails?.firstName
                            ?.charAt(0)
                            ?.toUpperCase() +
                            item?.userDetails?.firstName
                              ?.slice(1)
                              ?.toLowerCase() +
                            " " +
                            item?.userDetails?.lastName
                              ?.charAt(0)
                              ?.toUpperCase() +
                            item?.userDetails?.lastName
                              ?.slice(1)
                              ?.toLowerCase()}
                        </div>
                        {/* <div className="widget-subheading opacity-7">UI Designer</div> */}
                      </div>
                    </div>
                  </div>
                ),
                booking_id: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item?.alpfaNueID}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
                Booking_status: (
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <button
                          disabled={
                            item?.bookingStatus === "cancelled" ? true : false
                          }
                          className={`btn ${
                            item?.bookingStatus === "confirmed"
                              ? "btn-primary"
                              : "btn-secondary"
                          }`}
                          style={{
                            width: "90px",
                            backgroundColor:
                              item?.bookingStatus === "confirmed"
                                ? "green"
                                : "red",
                          }}
                          // onClick={() => handleClick(item?._id)}
                          // onClick={() => cancelBooking(item?._id)}
                        >
                          {item?.bookingStatus}
                        </button>
                          
                    <button
                      className="me-2 btn-icon btn-icon-only btn btn-outline-success ml-2"
                      disabled={(item?.bookingStatus === "cancelled"&&!item?.isRefund)?false:true}
                      style={{
                   
                        paddingLeft:"20px",
                        fontWeight:item?.isRefund?"bold":null
                        // backgroundColor:
                        //   item?.bookingStatus === "cancelled"
                        //     ? "green"
                        //     : "red",
                      }}
                      onClick={()=>{
                        setsingleid(item?._id)
                        setopenModel(true)
                     
                      }}
                    >
                      {item?.isRefund?"Already Refunded": "Refund price"}
              
                    </button>
                      </div>
                    </div>
                  </div>
                ),
                review_status:(
                  <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2 d-flex align-items-center">
                    <div
                          className="widget-heading"
                          style={{ opacity: 1, textAlign: "center" }}
                        >
                          {item.userReviewStatus}
                       

                        </div>
                        {item.userReviewStatus==="Unverified Review"&& <Link to={`/viewReviewAndRating/${item?.activity[0]?._id}`} className="ml-2 btn-icon btn-icon-only btn btn-outline-primary">
                          <i className="fa fa-share" aria-hidden="true"></i>
                          </Link>}
                    
                    </div>
                  </div>
                </div>
                ),
                detail_view: (
                  <div>
                    <Link to={ {pathname:`/booking-details/${item?._id}`,state:{item}}} >
                    {/* <Link > */}
                      <button className="me-2 btn-icon btn-icon-only btn btn-outline-primary">
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </button>
                    </Link>
                    
                   
                    
                  </div>
                ),
              };
            })
          : [],
    };
  }, [bookingData, searchText]);

  return (
    <>
      {isLoading && <Loader />}
      <form className="" onSubmit={(e) => handleSubmit(e)}>
        <div component="div" className="TabsAnimation appear-done enter-done">
          <div className="main-card mb-3 card">
            <div className="card-body">
              <div className="card-title">Activity Bookings</div>
              <div className="row" style={{ padding: "1rem" }}>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label
                      htmlFor="marchantId"
                      className="form-label font-weight-bold"
                    >
                      Merchants*
                    </label>
                    <Select
                      name="marchantId"
                      id="marchantId"
                      classNamePrefix="select"
                      // ref={inputRef}
                      options={merchants?.map((item, idx) => ({
                        value: item?._id,
                        label:
                          item?.firstName?.charAt(0)?.toUpperCase() +
                          item?.firstName?.slice(1)?.toLowerCase() +
                          " " +
                          item?.lastName?.charAt(0)?.toUpperCase() +
                          item?.lastName?.slice(1)?.toLowerCase(),
                      }))}
                      value={{
                        value: activity.marchantId,
                        label: activity.merchantName,
                      }}
                      // isMulti
                      onChange={(val) => {
                        // console.log("value", val);
                        setactivity((prev) => {
                          // console.log(prev);
                          return {
                            ...prev,
                            marchantId: val?.value,
                            merchantName: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label
                      htmlFor="activityId"
                      className="form-label font-weight-bold"
                    >
                      Activity*
                    </label>
                    <Select
                      name="activityId"
                      id="activityId"
                      classNamePrefix="select"
                      ref={inputRef}
                      options={activities?.map((item, idx) => ({
                        value: item?._id,
                        label: item?.activityTitle,
                      }))}
                      // isMulti
                      value={{
                        value: activity?.activityId,
                        label: activity?.activityTitle,
                      }}
                      onChange={(val) => {
                        setactivity((prev) => {
                          return {
                            ...prev,
                            activityId: val?.value,
                            activityTitle: val?.label,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      Start Date
                    </label>
                    <DatePicker
                      id="tourDate"
                      inputRef={inputRef}
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}
                      value={activity.startDate}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleStartDateChange}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="mb-3">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="tourDate"
                    >
                      End Date
                    </label>
                    <DatePicker
                      id="tourDate"
                      inputRef={inputRef}
                      calendarIcon={null}
                      className="form-control"
                      clearIcon={null}
                      value={activity.endDate}
                      format="y-MM-dd"
                      // closeCalendar  // on 'select'
                      shouldCloseCalendar={({ reason }) =>
                        reason === "select" || reason === "outsideAction"
                      }
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </div>

              {/* <button
                className="mt-2 btn btn-primary ml-auto d-table"
                type="submit"
                onClick={() => {}}
              >
                Submit
              </button> */}
              <div className="buttons">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mt-2 btn btn-link d-table"
                    onClick={handleReset}
                    style={{ marginRight: "10px" }}
                  >
                    Reset
                  </button>
                  <button
                    className="mt-2 btn btn-primary d-table"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Table
        striped
        tableTitle="All Booking Details"
        data={tableData}
        onSearch={(val) => setsearchText(val)}
      />


    {openModel&& singleid&&<SingleModel singleid={singleid} setopenModel={setopenModel}  allbookingdata={allbookingdata} />}  
    </>
  );
}

export default ViewBookings;
